import * as React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles({
	root: {
		width: "100%",
		padding: "0",
	},
});

export default function EopCalloutCards({ siteAudience, callOneClass, callOneContent, callOneLink, newTabOne, callTwoClass, callTwoLink, newTabTwo, callTwoContent, footnoteOne, footnoteTwo }) {
	const classes = useStyles();	
	// card style types: 
	// - "akebia-cares" for card with akebiacares logo
	// - "highlight" for a card with background color on mobile 
	// - "default" for a card with no image and no background color on mobile
	return (
		<section id="eop-callouts" className={[siteAudience, "anims"].join(' ')}>
			<div className="flex-row">
				<div>
					<Card className={[classes.root, callOneClass].join(' ')}
					>
						{callOneLink !== undefined ?
							<CardActionArea href={callOneLink}
								data-sal="slide-right"
								data-sal-duration="600"
								data-sal-delay="300"
								data-sal-easing="ease-out-expo"
								target={newTabOne != null ? newTabOne : "_self"}>
								<CardContent>
									{callOneContent}
								</CardContent>
							</CardActionArea>
							:
							<div
								data-sal="slide-right"
								data-sal-duration="600"
								data-sal-delay="300"
								data-sal-easing="ease-out-expo">
								<CardContent>
									{callOneContent}
								</CardContent>
							</div>
						}
					</Card>

					<p className="footnote center"
						data-sal="slide-right"
						data-sal-duration="600"
						data-sal-delay="300"
						data-sal-easing="ease-out-expo"
					>
						{footnoteOne &&
							<>{footnoteOne}</>
						}
					</p>
				</div>
				<div>
					<Card className={[classes.root, callTwoClass].join(' ')}
					>
						{callTwoLink !== undefined ?
							<CardActionArea href={callTwoLink}
								data-sal="slide-left"
								data-sal-duration="600"
								data-sal-delay="500"
								data-sal-easing="ease-out-expo"
								target={newTabTwo != null ? newTabTwo : "_self"}
							>
								<CardContent>
									{callTwoContent}
								</CardContent>
							</CardActionArea>
						:
							<div data-sal="slide-left"
								data-sal-duration="600"
								data-sal-delay="500"
								data-sal-easing="ease-out-expo"
							>
								<CardContent>
									{callTwoContent}
								</CardContent>
							</div>
						}
					</Card>
					<p className="footnote center"
						data-sal="slide-left"
						data-sal-duration="600"
						data-sal-delay="500"
						data-sal-easing="ease-out-expo">
						{footnoteTwo &&
							<>{footnoteTwo}</>
						}
					</p>
				</div>
			</div>
		</section>
	);
}
