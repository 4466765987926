import * as React from "react"

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
// import ReactPlayerLoader from '@brightcove/react-player-loader';



const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		flexFlow: 'row wrap',
		outline: '1px dotted #eee',
	},
	modalCloseBttn: {
		position: 'absolute',
		top: '18px',
		right: '18px',
		width: '20px',
		height: '20px',
	},
	modalCloseSvg: {
		width: '20px',
		height: '20px',
	},
	modalLogo: {
		maxWidth: '180px',
		height: 'auto',
	},
	modalText: {
		fontSize: '3rem',
		lineHeight: '25px',
		fontFamily: "'Avenir LT W01_65 Medium1475532', sans-serif",
		margin: '29px auto',
		[theme.breakpoints.up('sm')]: {
			fontSize: '30px',
			lineHeight: '5rem',
			margin: '6rem auto 6.75rem',
		},
	},
	bttnWrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		flexFlow: 'row wrap',
		width: '100%',
	},
	cta: {
		whiteSpace: 'nowrap',
		minWidth: '295px',
		maxWidth: '303px',
		width: '48%',
		minHeight: '4rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexFlow: 'row wrap',
		fontSize: '11px',
		lineHeight: 0,
		padding: '0.5rem 1rem',
		margin: '0.5rem 1rem',
		borderWidth: '2px',
		borderStyle: 'solid',
		textTransform: 'uppercase',
		backgroundColor: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		fontFamily: "'Avenir LT W01_95 Black1475556', sans-serif",
		letterSpacing: '0.5px',
		transition: 'all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1)',
		'&:hover': {
			color: 'white',
		},
		'&:nth-child(1)': {
			marginBottom: '2rem',
		},
		[theme.breakpoints.up('sm')]: {
			'&:nth-child(1)': {
				marginBottom: '0.5rem',
			}
		},
		[theme.breakpoints.up('md')]: {
			'&:nth-child(1)': {
				marginRight: '3.75rem',
			},
		},
	},
	paper: {
		backgroundColor: 'white',
		position: 'relative',
		border: 'none',
		boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'center',
		alignItems: 'center',
		width: '94%',
		margin: '0 3rem',
		padding: '46px 4rem 42px',
		maxWidth: '116rem',
		borderRadius: '5px',
		[theme.breakpoints.up('sm')]: {
			margin: '0 auto',
			maxWidth: '116rem',
			padding: '9rem 5rem',
		}
	},
}));
// export default function VideoInModal({ accountId, playerId, videoId, posterImg, altText, siteAudience }) {
export default function VideoInModal({ url, posterImg, altText, siteAudience, width, height }) {
	// let player;
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	let videoLabel;
	// switch (videoId) {
	// 	case '6189938633001':
	// 		videoLabel = "HP MOA Video";
	// 		break;
	// 	case '6262901999001':
	// 		videoLabel = "A Team Approach to Treating Hyperphosphatemia Video";
	// 		break;
	// 	case '6189938842001':
	// 		videoLabel = "IDA MOA Video";
	// 		break;
	// 	case '6262904611001':
	// 		videoLabel = "A Team Approach to Treating Iron Deficiency Anemia";
	// 		break;

	// }
	switch (url) {
		case 'https://www.youtube.com/embed/LVGUnE2m7xQ':
			videoLabel = "HP MOA Video";
			break;
		case 'https://www.youtube.com/embed/YKjUfwxwgdQ':
			videoLabel = "A Team Approach to Treating Hyperphosphatemia Video";
			break;
		case 'https://www.youtube.com/embed/2trKJNelHYA':
			videoLabel = "IDA MOA Video";
			break;
		case 'https://www.youtube.com/embed/ofO-dcujLT8':
			videoLabel = "A Team Approach to Treating Iron Deficiency Anemia";
			break;
	
	}

	// let dataLayer = window.dataLayer = window.dataLayer || [];  

	function onPlay(e) {
		//console.log(videoLabel + ": video play");

		window.dataLayer.push({
			'event': 'gtm-video',
			'attributes': {
				'eventAction': 'Video Start',
				'eventLabel': videoLabel,
				'eventCategory': 'Service and support'
			}

		});

	}

	function onPause(e) {
		//console.log(videoLabel + ": video pause");

		window.dataLayer.push({
			'event': 'gtm-video',
			'attributes': {
				'eventAction': 'Video Pause',
				'eventLabel': videoLabel,
				'eventCategory': 'Service and support'
			}

		});

	}


	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};
	return (
		<>
			<div className="modal">
				<IconButton
					//onTouchStart={handleOpen} // <-- this was causing a bug on mobile that was auto closing it
					onClick={handleOpen}>
					<PlayCircleFilledWhiteIcon viewBox="2 2 20 20" />
					<img src={posterImg} alt={altText} />
				</IconButton>
			</div>
			<button onClick={handleOpen} className={[siteAudience, 'cta'].join(' ')}><span>WATCH NOW</span></button>

			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={[classes.modal]}
				onClose={handleClose}
				closeAfterTransition
				open={open}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				id="video-modal"
			>
				<Fade timeout={{ enter: 1000, exit: 800, }} in={open}>
					<div className={classes.paper}>
						<IconButton
							color="inherit"
							aria-label="close modal"
							onClick={handleClose}
							id="close-button"
							className={classes.modalCloseBttn}>
							<CloseIcon viewBox="6 6 12 12" className={classes.modalCloseSvg} />
						</IconButton>
						{/* <ReactPlayerLoader
							accountId={accountId}
							playerId={playerId}
							videoId={videoId}
							poster={posterImg}
							onSuccess={(success) => {
								console.log('success');

								var myPlayer = success.ref;

								myPlayer.on("play", onPlay);
								myPlayer.on("pause", onPause);

							}
							}
						/> */}

						{/* <iframe src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen width={width} height={height}></iframe> */}
						<iframe src={url} title="YouTube video player" width="459" height="256" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

					</div>
				</Fade>
			</Modal>
		</>
	)
}